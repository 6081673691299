// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hasznos-szolgaltatasok-js": () => import("./../../../src/pages/hasznos-szolgaltatasok.js" /* webpackChunkName: "component---src-pages-hasznos-szolgaltatasok-js" */),
  "component---src-pages-hasznos-terkepek-js": () => import("./../../../src/pages/hasznos-terkepek.js" /* webpackChunkName: "component---src-pages-hasznos-terkepek-js" */),
  "component---src-pages-hirek-js": () => import("./../../../src/pages/hirek.js" /* webpackChunkName: "component---src-pages-hirek-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jatekok-js": () => import("./../../../src/pages/jatekok.js" /* webpackChunkName: "component---src-pages-jatekok-js" */),
  "component---src-pages-kit-tamogassak-js": () => import("./../../../src/pages/kit-tamogassak.js" /* webpackChunkName: "component---src-pages-kit-tamogassak-js" */),
  "component---src-pages-kozossegek-js": () => import("./../../../src/pages/kozossegek.js" /* webpackChunkName: "component---src-pages-kozossegek-js" */),
  "component---src-pages-kozossegi-jatekok-js": () => import("./../../../src/pages/kozossegi-jatekok.js" /* webpackChunkName: "component---src-pages-kozossegi-jatekok-js" */),
  "component---src-pages-sutitajekoztato-js": () => import("./../../../src/pages/sutitajekoztato.js" /* webpackChunkName: "component---src-pages-sutitajekoztato-js" */),
  "component---src-pages-tamogass-minket-js": () => import("./../../../src/pages/tamogass-minket.js" /* webpackChunkName: "component---src-pages-tamogass-minket-js" */)
}

